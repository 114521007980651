
export default function FooterBar() {
  
  const timestamp = Date.now()
  const date = new Date(timestamp)
  const year = date.getFullYear()

  return (
    <div className="footer-bar box">
      <p className="t-left t-m-center">{`Wszelkie prawa zastrzeżone ${year} SABIO | Projekt i wykonanie Hubert Pulik i Karol Niewęgłowski`}</p>
    </div>
  )
}
