
export default function ContactForm() {
  return (
    <div className="contact-form">
      <h2 className="s2 t-left mt3 mb3">Formularz kontakotwy</h2>
      <form action="" className="flex column">
        <label htmlFor="email" className="s1_5 mb1">
          Twój email
        </label>
        <input type="email" name="email" className="s1_5 mb1"/>
        <label htmlFor="name" className="s1_5 mb1">
          Twóje imię
        </label>
        <input type="text" name="name" className="s1_5 mb1"/>
        <label htmlFor="phone" className="s1_5 mb1">
          Twój telefon
        </label>
        <input type="text" name="phone" className="s1_5 mb1"/>
        <label htmlFor="info" className="s1_5 mb1">
          Twója wiadomość
        </label>
        <textarea type="textarea" rows="5" name="info" className="s1_5 mb1"/>
        <button type="submit" className="btn">Wyślij</button>
      </form>
      
    </div>
  )
}
