
export default function SingleStep({ item }) {
  return (
    <div className={`single-step single-step-${item.side} w50 flex column`}>
      <div className="step-id flex a-center mt1">
        <div className="step-id-inner">{item.id}</div>
        <div className="step-id-bar"></div>
      </div>
      <h3 className="step-heading mt2">{`Etap ${item.id}`}</h3>
      <p className="step-desc mt2">{item.text}</p>
    </div>
  )
}
