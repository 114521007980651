import Navigation from "../../layout/Navigation";

export default function FooterB() {
  return (
    <div className="footerB">
      <h2>Menu</h2>
      <Navigation location={'footer-menu'} />
    </div>
  )
}
