// import { useState, useEffect } from "react"
// import { useCollection } from "../hooks/useCollection"

export default function Terms() {

  // const { documents } = useCollection('start-page')
  // console.log(documents)

  return (
    <div className="info-page">
      <h1 className="t-center">Polityka prywatności</h1>
    </div>
  )
}
