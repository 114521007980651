import { useState } from 'react'
import { useNavigate } from 'react-router'

import config from '../../config.json' 
import Button from '../layout/Button'

export default function ColorSwitcher() {

  const URL = config.URL

  const destination = useNavigate()

  const navigate = (link) => {
    destination(link)
  }

  const [ activeColor, setActiveColor ] = useState('008.png')

  return (
    <div className='color-switcher'>
      <img src={`${URL}/assets/colors/${activeColor}`} alt="domek" />
      <p className='mt3 t-center'>Przykładowe warianty koolorystyczne elewacji</p>
      <div className='colors-btn mt1'>
        <div className='single-color' onClick={() => setActiveColor('001.png')} style={{backgroundColor: "#26382D"}}></div>
        <div className='single-color' onClick={() => setActiveColor('002.png')} style={{backgroundColor: "#1B2636"}}></div>
        <div className='single-color' onClick={() => setActiveColor('003.png')} style={{backgroundColor: "#89909B"}}></div>
        <div className='single-color' onClick={() => setActiveColor('004.png')} style={{backgroundColor: "#5E232e"}}></div>
        <div className='single-color' onClick={() => setActiveColor('005.png')} style={{backgroundColor: "#978769"}}></div>
        <div className='single-color' onClick={() => setActiveColor('006.png')} style={{backgroundColor: "#898783"}}></div>
        <div className='single-color' onClick={() => setActiveColor('007.png')} style={{backgroundColor: "#454647"}}></div>
        <div className='single-color' onClick={() => setActiveColor('008.png')} style={{backgroundColor: "#E1E4ED"}}></div>
      </div>
      <div className='m-auto flex j-center a-center mt2'>
        <Button content={'Zobacz pełną paletę'} action={navigate} option={'/probnik'} alert={'Kliknięcie normalnego przycisku'} type={'btn'}/>
      </div>
    </div>
  )
}
