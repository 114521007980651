
export default function Address({ location }) {
  return (
    <div className={`${location} address`}>
      <h3 className="address-heading">Sabio</h3>
      <p className="address-par">Biała 1a</p>
      <p className="address-par">21-300 Radzyń Podlaski</p>
      <p className="address-par">biuro@sabio.com.pl</p>
      <p className="address-phone"><strong>500 18 30 30</strong></p>
    </div>
  )
}
