
export default function ListSection({ content }) {
  return (
    <div className="list-section flex a-center j-center column g3 box m-auto">
      <h2 className="list-heading s4 mb3 t-m-center">{content.heading}</h2>
      <div className="list-box flex j-center a-center">
        {content.list.map(item => (
          <div key={item} className="list-item s2 w-800">{item}</div>
        ))}
      </div>
    </div>
  )
}
