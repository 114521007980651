import { NavLink } from 'react-router-dom'

import HamburgerIcon from './HamburgerIcon'
import Logo from './Logo'
import Navigation from './Navigation'

export default function Header({ handleModal }) {
  return (
    <div className='header-wrapper m-auto'>
      <div className='header flex j-beetween a-center g2 box light m-auto'>
        <div className='header-inner-left'>
          <NavLink to='/' ><Logo /></NavLink>
        </div>
        <div className='header-inner-right flex j-end a-center g2'>
          <div className='desktop'>
            <Navigation location={'header-menu'}/>
          </div>
          <HamburgerIcon handleModal={handleModal}/>
        </div>
      </div>
    </div>
    
  )
}
