import Address from "./Address";

export default function Map() {
  return (
    <div className="map-section flex j-center a-center column">
      <h2 className="map-heading s4 mt2 t-m-center">Gdzie nas znajdziesz?</h2>
      <div className="map-address">
        <Address />
      </div>
      <div className="map-container mt3">
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d37009.8118073807!2d22.553862073051974!3d51.78026728185105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x472219436cbc327d%3A0x56d55c8a18aa1780!2zQmlhxYJhIDFBLCAyMS0zMDAgQmlhxYJh!5e0!3m2!1spl!2spl!4v1695527792367!5m2!1spl!2spl" 
          width="100%" height="450"  
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Maps Iframe"
          style={{border:"0"}}>
        </iframe>
      </div>
    </div>
  )
}