import React from 'react'
import Navigation from './Navigation'
// import Icons from './Icons'

export default function MobileMenu() {
  return (
    <div className='mobile__menu'>
      <Navigation location={'mobile-menu'}/>
      {/* <Icons /> */}
    </div>
  )
}
