import Button from "../components/layout/Button";

export default function DesignSystem() {
  return (
    <div className="design-system box m-auto">
      <h1 className="mb2">Nagłówek pierwszego stopnia</h1>
      <h2 className="mb2">Nagłowek drugiego stopnia</h2>
      <h3 className="mb2">Nagłowek trzeciego stopnia</h3>
      <p className="mb2"><strong>Akapit z Tekstem pogrubionym. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim, laboriosam? Quasi dignissimos dolorum, animi sint maxime aperiam, quo nostrum perspiciatis, quam id magni qui! Minima consequatur provident quibusdam laboriosam sequi!</strong></p>
      <p className="mb2">Akapit z tekstem normalnym. Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt a ipsam doloribus. Nemo fuga, ullam soluta quaerat vel sunt quae natus commodi. Quaerat nam voluptates laudantium officiis earum id modi.</p>
      <p className='italic-font mb2'>Akapit z tekstem w kursywie. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempore magni, repudiandae pariatur omnis consequuntur aspernatur minus impedit ducimus ut nihil rerum sit minima. Itaque expedita optio sit ratione, eos facilis.</p>
      <p className='alt-font mb2'>Akapit z tekstem w alternatywnym kroju. Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere voluptatem quia natus omnis. Quos illum culpa facere amet, nisi delectus blanditiis ut sint aperiam, mollitia vel similique fuga quasi odit.</p>
      <div className='flex j-start g3 mb2'>
        <Button content={'Przycisk normalny'} action={console.log} option={'normalny'} alert={'Kliknięcie normalnego przycisku'} type={'btn'}/>
        <Button content={'Przycisk wyróżniony'} action={console.log} option={'alert'} alert={'Kliknięcie wyróżnionego przycisku'} type={'btn-alert'}/>
        <Button content={'Przycisk CTA'} action={console.log} option={'cta'} alert={'Kliknięcie przycisku CTA'} type={'btn-cta'}/>
      </div>
     
    </div>
  )
}
