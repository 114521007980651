import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './App.css';

import Start from './routes/Start';
import Offer from './routes/Offer';
import About from './routes/About';
import Contact from './routes/Contact';
import Terms from './routes/Terms';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Modal from './components/layout/Modal';
import MobileMenu from './components/layout/MobileMenu';
import DesignSystem from './routes/DesignSystem';
import ColorSamples from './routes/ColorSamples';
import AdminStart from './routes/AdminStart';

function App() {

  const [ isModal, setIsModal ] = useState(false)

  // const authUser = useAuth()

  const handleModal = () => {
    setIsModal(prevIsModal => !prevIsModal)
  }

  return (
    <main className='main'>
    <BrowserRouter>
      <Header handleModal={handleModal}/>
      <Modal isModal={isModal} handleModal={handleModal}>
        <MobileMenu handleModal={handleModal}/>
      </Modal>
      <Routes>
        <Route path='/' element={<Start />} />
        <Route path='/domki' element={<Offer />} />
        <Route path='/jak-to-dziala' element={<About />} />
        <Route path='/kontakt' element={<Contact />} />
        <Route path='/polityka-prywatnosci' element={<Terms />} />
        <Route path='/design-system' element={<DesignSystem />} />
        <Route path='/probnik' element={<ColorSamples />} />
        <Route path='/admin/start' element={<AdminStart />} />
      </Routes>
      <Footer />
    </BrowserRouter>
    </main>
  );
}

export default App;
