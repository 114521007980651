import React from 'react'
import MobileMenu from './MobileMenu'

export default function Modal({ isModal, handleModal }) {
  return (
    <div className={`modal ${isModal ? 'visible' : 'hidden'}`} onClick={() => handleModal()}>
      <div className='modal-inner bg-dark bg-blur'>
        <MobileMenu />
      </div>
    </div>
  )
}
