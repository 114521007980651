// import { useState, useEffect } from "react"
import { useCollection } from "../hooks/useCollection"

import config from '../config.json'

import Hero from "../components/content/Hero";
import IconsSection from "../components/content/IconsSection";
import Separator from "../components/layout/Separator";
import ListSection from "../components/content/ListSection";
import Gallery from "../components/layout/Gallery";
import Map from "../components/content/Map";
import AdminImageSection from "../components/admin/AdminImageSection";

export default function AdminStart() {

  const { documents } = useCollection('start-page')

  const URL = config.URL

  const photos = [
    { src: `${URL}/assets/foto/sabio-01.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-02.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-03.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-04.jpg`, width: 2577, height: 1718 },
    { src: `${URL}/assets/foto/sabio-05.jpg`, width: 2577, height: 1718 },
  ]

  return (
    <div className="start-page m-auto">
      <Hero />
      {documents && 
      <div className="content">
        <Separator height={'100px'} />
        <AdminImageSection content={documents[0]} type={'normal'} />
        <Separator height={'100px'} />
        <IconsSection content={documents[1].icons}/>
        <Separator height={'100px'} />
        <AdminImageSection content={documents[2]} type={'reverse'} />
        <Separator height={'100px'} />
        <ListSection content={documents[3]} />
        <Separator height={'100px'} />
        <Gallery photos={photos} />
        <Separator height={'100px'} />
        <Map />
      </div>
      }
    </div>
  )
}
