import { NavLink } from "react-router-dom"

export default function Navigation({ location }) {
	return(
		<div className={`navigation ${location}`}>
      <ul className="navigation-list flex m-column g2">
        <li key='1'><NavLink to='/'>Start</NavLink></li>
        <li key='2'><NavLink to='/domki'>Domki</NavLink></li>
        <li key='3'><NavLink to='/jak-to-dziala'>Jak to działa</NavLink></li>
        <li key='4'><NavLink to='/kontakt'>Kontakt</NavLink></li>
		  </ul>
    </div>
	)
}