import config from '../../config.json'

import { motion } from 'framer-motion'

export default function IconsSection({ content }) {

  const URL = config.URL

  const variants = {
    active: {
      scale: 1.1,
      rotate: 0,
      transition: {
        duration: 0.9,
        type: "spring", 
        stiffness: 100
      }
    },
    initial: {
      opacity: 0,
      y: -100,
      transition: { 
        duration: 0.7
      }
    },
    animate: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: { 
        duration: 0.7
      }
    }
  }

  return (
    <div className="icons-section flex j-center a-center g3 box m-auto mt4 mb4 m-column">
      {content.map(icon => (
        <div key={icon.id} className="icon-box flex column j-start a-center g2">
          <motion.div className='icon-img-box' 
          whileHover='active' 
          initial='initial'
          whileInView='animate'
          viewport={{
            once: true,
          }}
          variants={variants}>
            <img src={`${URL}/assets/${icon.icon}`} alt="ikona" className='icon-img'/>
          </motion.div>
          <h3 className='icon-heading s2_5'>{icon.heading}</h3>
          <p className='icon-par t-center'>{icon.description}</p>
        </div>
      ))}
    </div>
  )
}
 