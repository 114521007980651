import ReactMarkdown from 'react-markdown'

export default function ColumnListSection({ list }) {
  return (
    <div className="column-list-section m-auto box">
      <ul className='column-list'>
        {list.map(item => (
          <li key={item} className='column-list-item'><ReactMarkdown children={item} /></li>
        ))}
      </ul>
    </div>
  )
}
