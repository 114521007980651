import config from '../../config.json' 

export default function Logo() {

  const URL = config.URL

  return (
    <div className="logo m-auto">
      <img src={`${URL}/assets/logo-l.svg`} alt="logo" />
    </div>
  )
}
