import FooterA from "../content/footer/FooterA";
import FooterB from "../content/footer/FooterB";
import FooterBar from "../content/footer/FooterBar";
import FooterC from "../content/footer/FooterC";
import FooterD from "../content/footer/FooterD";

export default function Footer() {
  return (
    <div className='footer flex j-center a-center bg-dark light column'>
      <div className="footer-top flex j-start a-start g3 box mt2 mb2 m-column">
        <FooterA />
        <FooterB />
        <FooterC />
        <FooterD />
      </div>
      <div className="footer-bottom flex j-start box mb1">
        <FooterBar />
      </div>
    </div>
  )
}
