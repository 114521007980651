import ReactMarkdown from 'react-markdown'

export default function ParagraphSection({ content }) {

  return (
    <div className='paragraph-section box m-auto'>
      {content.map(item => (
            <div className='basic-par'>
              <ReactMarkdown children={item} />
            </div>
          ))}
    </div>
  )
}
