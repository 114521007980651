import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyBkbLoFGnYgmB6tMr8QP-mvkbN0Nvd9AvY",
  authDomain: "sabio-2d29d.firebaseapp.com",
  projectId: "sabio-2d29d",
  storageBucket: "sabio-2d29d.appspot.com",
  messagingSenderId: "1060930735308",
  appId: "1:1060930735308:web:b53721c96df5eb9dd68669"
};

const app = initializeApp(firebaseConfig)

const db = getFirestore(app)

export { db }