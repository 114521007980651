// import { useState, useEffect } from "react"
// import { useCollection } from "../hooks/useCollection"

import Address from "../components/content/Address";
import ContactForm from "../components/layout/ContactForm";
import Separator from "../components/layout/Separator";

export default function Contact() {

  // const { documents } = useCollection('start-page')
  // console.log(documents)

  return (
    <div className="contac box m-auto">
      <h2 className="s3 t-center mt3 mb3">Kontakt</h2>
      <div className="flex a-center j-center m-column g4">
        <div className="contact-left w100 flex j-end a-start">
          <Address location={'contact-page'}/>
        </div>
        <div className="contact-right w100">
          <ContactForm />
        </div>
      </div>
      <Separator height={'200px'} />
    </div>
  )
}
