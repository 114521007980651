import Logo from "../../layout/Logo";
import Address from "../Address";

export default function FooterD() {
  return (
    <div className="footerD">
      <div className="m-auto">
        <Logo />
      </div>
      <Address location={'footer-address'} />
    </div>
  )
}
