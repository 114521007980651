import SingleStep from "./SingleStep";

export default function Steps({ content }) {
  return (
    <div className="steps box m-auto flex column">
      <div className="steps-separator w50"></div>
      {content.map(item => (
        <SingleStep item={item} />
      ))}
      <div className="steps-separator w50"></div>
    </div>
  )
}
