import React from 'react'

export default function Edit({ item }) {
  return (
    <div className='edit-content'>
      <p>Edycja sekcji</p>
      <p>{item}</p>
    </div>
  )
}
