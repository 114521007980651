import Gallery from "../layout/Gallery";

export default function SampleCat({ samples, headerImgs, name }) {
  return (
    <div className="sample-cat">
      
      <div className="flex j-between a-center g3 m-column">
        <h3 className="s3 w100">{name}</h3>
        <img src={headerImgs[0].url} alt={headerImgs[0].alt} />
        <img src={headerImgs[1].url} alt={headerImgs[1].alt} />
      </div>
      <Gallery photos={samples} targetRowHeight={150} />
    </div>
  )
}
