import OptionalNavigation from "../../layout/OptionalNavigation";

export default function FooterC() {
  return (
    <div className="footerC">
      <h2>Przydatne Linki</h2>
      <OptionalNavigation location={'footer-menu'} />
    </div>
  )
}
