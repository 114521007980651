import { useState, useEffect } from "react"

export default function Button({ content, action, option, alert, type }) {

  const [ idle, setIdle ] = useState(false)

  useEffect(() => {
    if (idle === true) {
      const interval = setInterval(() => {
        setIdle(false)
      }, 1500);
      return () => clearInterval(interval);
    }
  }, [idle]);

  return (
    <>
    {idle ? 
    <button className={`${type} idle`}>
      {alert}
    </button> :
    <button className={type} onClick={() => {setIdle(true); action(option)}}>
      {content}
    </button>
    }
    </>
  )
}
