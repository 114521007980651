import SampleCat from "../components/content/SampleCat";
import MobileSeparator from "../components/layout/MobileSeparator";

import config from '../config.json'

export default function ColorSamples() {

  const URL = config.URL

  const viny_plus_rundprofil = {
    name: 'vinyPlus Rundprofil',
    headerImgs: [
      {
        url: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rund_querschnitt_profil.png`,
        alt: 'vinyplus_rund_querschnitt_profil'
      },
      {
        url: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rund_profil.png`,
        alt: 'vinyplus_rund_profil'
      }
    ],
    samples: [
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_anteak_grey.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_anthrazitgrau_7016.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_basaltgrau.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_bergkiefer.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_cremeweiss_9001.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_dunkelrot_3011.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_goldenoak.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_Grau.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_jura_pine.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_lichtgrau_ral7035.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_sheffield_oak_alpin.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_sheffield_oak_beton.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_stahlblau_5011.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_turner_oak_malz.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_vinyplus_dunkelgruen.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_weiss_ral_9010.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_woodec_artisan.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_woodec_kitami.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/viny-plus-rundprofil/vinyplus_rundprofil_frontal_woodec_turner_oak_toffee.jpg`, width: 514, height: 580 }
    ]
  }

  const plus_quattro = {
    name: 'vinyPlus Quattro',
    headerImgs: [
      {
        url: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_querschnitt_profil.jpg`,
        alt: 'vinyplus_quattro_querschnitt_profil'
      },
      {
        url: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_profil.jpg`,
        alt: 'vinyplus_quattro_profil'
      }
    ],
    samples: [
      { src: `${URL}/assets/samples/plus_quattro/viny_quattro_frontal_turner_oak_toffee.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_frontal_anteak.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_frontal_jura_pine.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_frontal_sheffield_oak_alpin.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_frontal_sheffield_oak_beton.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_frontal_signalweiss_ultramatt.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_frontal_turner_oak_malz.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_frontal_weissbach_eiche.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_frontal_woodec_artisan.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_quattro/vinyplus_quattro_frontal_woodec_kitami.jpg`, width: 514, height: 580 }
    ]
  }

  const plus_shadow = {
    name: 'vinyPlus Shadow',
    headerImgs: [
      {
        url: `${URL}/assets/samples/plus_shadow/vinyplus_shadow_querschnitt_profil.jpg`,
        alt: 'vinyplus_shadow_querschnitt_profil'
      },
      {
        url: `${URL}/assets/samples/plus_shadow/vinyplus_shadow_profil.jpg`,
        alt: 'vinyplus_shadow_profil'
      }
    ],
    samples: [
      { src: `${URL}/assets/samples/plus_shadow/vinyplus_shadow_frontal_jura_pine.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_shadow/vinyplus_shadow_frontal_sheffield_oak_alpin.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_shadow/vinyplus_shadow_frontal_sheffield_oak_beton.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_shadow/vinyplus_shadow_frontal_turner_oak_malz.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_shadow/vinyplus_shadow_frontal_woodec_artisan.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_shadow/vinyplus_shadow_frontal_woodec_kitami.jpg`, width: 514, height: 580 },
      { src: `${URL}/assets/samples/plus_shadow/vinyplus_shadow_frontal_woodec_turner_oak_toffee.jpg`, width: 514, height: 580 }
    ]
  }

  return (
    <div className="color-samples box m-auto">
      <h2 className="s3 m-auto t-center mb4 mt4">Wzorniki wykończenia elewacji</h2>
      <SampleCat samples={viny_plus_rundprofil.samples} headerImgs={viny_plus_rundprofil.headerImgs} name={viny_plus_rundprofil.name} />
      <MobileSeparator height={'150px'} />
      <SampleCat samples={plus_shadow.samples} headerImgs={plus_shadow.headerImgs} name={plus_shadow.name} />
      <MobileSeparator height={'150px'} />
      <SampleCat samples={plus_quattro.samples} headerImgs={plus_quattro.headerImgs} name={plus_quattro.name} />
      <MobileSeparator height={'150px'} />
    </div>
  )
}
