// import { useState, useEffect } from "react"
import { useNavigate } from 'react-router'

import { useCollection } from "../hooks/useCollection"

import ParagraphSection from "../components/content/ParagraphSection"
import Steps from "../components/content/Steps"
import Button from "../components/layout/Button"
import Separator from "../components/layout/Separator"

export default function About() {

  const { documents } = useCollection('about-page')
  console.log(documents)

  const destination = useNavigate()

  const navigate = (link) => {
    destination(link)
  }

  return (
  <>
    {documents &&
    <div className="about-page">
      <h2 className="t-center s3">{documents[0].heading}</h2>
      <ParagraphSection content={documents[0].paragraphs} />
      <Separator height={'100px'} />
      <Steps content={documents[1].steps} />
    </div>}
    <Separator height={'150px'} />
    <div className='flex box m-auto j-center a-center'>
      <Button content={'Skontaktuj się z nami'} action={navigate} option={'/kontakt'} alert={'Kliknięcie normalnego przycisku'} type={'btn'} />
    </div>
    
    <Separator height={'250px'} />
  </>
  )
}
